<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-63.mp3"),
      },
      timeStampList: [8.3, 12, 15.1, 17, 21.3, 23, 24],
      singList: {
        lyricsArr: [
          {
            id: 1,
            chineseWords: "zhi, chi, shi, ri, zi, ci, si",
          },
          {
            id: 2,
            chineseWords: "shīzi（狮子）, shīzi（狮子）",
          },
          {
            id: 3,
            chineseWords: "y, w, yi, wu, yu, ye, yue, yuan",
          },
          {
            id: 4,
            chineseWords: "yin, yun, ying",
          },
          {
            id: 5,
            chineseWords: "yīngwǔ（鹦鹉）",
          },
          {
            id: 6,
            chineseWords: "yīnyuè（音乐）",
          },
          {
            id: 7,
            chineseWords: "yuèliang（月亮）",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
